import React from "react";
import { StyledCard, StyledCardInner, HoverShape } from "./StyledCard";
import { CardProps } from "./types";

const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ ribbon, children, background, ...props }) => {
  return (
    <StyledCard {...props}>
      <StyledCardInner background={background} hasCustomBorder={!!props.borderBackground}>
        <HoverShape position="hover-shape-1" direction="left" />
        <HoverShape position="hover-shape-2" direction="left" />
        <HoverShape position="hover-shape-3" direction="right" />
        <HoverShape position="hover-shape-4" direction="right" />
        {ribbon}
        {children}
      </StyledCardInner>
    </StyledCard>
  );
};

export default Card;