import styled, { css, DefaultTheme } from "styled-components";
import { space } from "styled-system";
import { promotedGradient } from "../../util/animationToolkit";
import { Box } from "../Box"; // Ensure Box component exists in your project.
import { CardProps } from "./types";

// HoverShape Component
export const HoverShape = styled.span<{ position: string; direction: string }>`
  position: absolute;
  transition: 0.4s ease;
  opacity: 1; /* Always visible */
  transform: scale(1); /* Always at full scale */
  
  background: ${({ direction, theme }) =>
    direction === "left"
      ? `linear-gradient(rgb(0 106 255) -100%, rgba(21, 22, 37, 0) 64%)` 
      : `linear-gradient(to top, rgba(252, 220, 14, 1) -100%, rgba(21, 22, 37, 0) 64%)`}; 
  
  ${({ position }) =>
    position === "hover-shape-1"
      ? `top: 0px; left: 0px; width: 5px; height: 200px;`
      : position === "hover-shape-2"
        ? `top: 0px; left: 5px; width: 200px; height: 10px;`
        : position === "hover-shape-3"
          ? `bottom: 0px; right: 0px; width: 5px; height: 200px;`
          : `bottom: 0px; right: 5px; width: 200px; height: 10px;`}
`;

// StyledCard Component
interface StyledCardProps extends CardProps {
  theme: DefaultTheme;
  isActive: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  isWarning: boolean;
  borderBackground?: string;
}

const getBorderColor = ({ isActive, isSuccess, isWarning, borderBackground, theme }: StyledCardProps) => {
  if (borderBackground) {
    return borderBackground;
  }
  if (isWarning) {
    return theme.colors.warning;
  }
  if (isSuccess) {
    return theme.colors.success;
  }
  if (isActive) {
    return `linear-gradient(180deg, ${theme.colors.primaryBright}, ${theme.colors.secondary})`;
  }
  return theme.colors.cardBorder;
};

export const StyledCard = styled.div<StyledCardProps>`
  color: ${({ theme, isDisabled }) => theme.colors[isDisabled ? "textDisabled" : "text"]};
  overflow: hidden;
  position: relative;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.primaryBright}, ${theme.colors.secondary})`}; 
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${promotedGradient} 3s ease infinite;
      background-size: 400% 400%;
    `}
  ${space}
    background: ${({ theme }) => theme.colors.cardbackground};

  transition: background 0.4s ease, border-color 0.4s ease;
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const StyledCardInner = styled(Box) <{ background?: string; hasCustomBorder: boolean }>`
  // Your other styles for this component
`;

StyledCard.defaultProps = {
  isActive: false,
  isSuccess: false,
  isWarning: false,
  isDisabled: false,
};
